<template>
  <PatologiesPage
    title="Ernia jatale permagna"
    subtitle1="L’ernia jatale permagna o gigante è una patologia caratterizzata da una risalita di gran parte dello stomaco, almeno 1/3, dall’addome in torace. Va distinta dalla comune ernia jatale, molto diffusa nella popolazione e che è caratterizzata invece da una risalita di pochi centimetri dello stomaco e che si associa generalmente alla malattia da reflusso gastro-esofageo. L’ernia jatale permagna è in molti casi una condizione asintomatica e la diagnosi spesso avviene in maniera casuale con una TAC torace o un Rx torace, effettuati per altri motivi. Altri pazienti invece possono presentare sintomi da ingombro mediastinico quali facile affaticabilità, mancanza di fiato, anche dopo sforzi moderati, cardiopalmo oppure sintomi di tipo gastroenterologico quali sensazione di difficoltà al transito del bolo alimentare e rigurgito, oppure sintomi da strangolamento d’ernia come il dolore toracico. Sintomi come la sensazione di gonfiore addominale o difficoltà digestive non sono in genere imputabili alla presenza dell’ernia."
    subtitle2="Gli esami che sono necessari per un corretto inquadramento sono l’Rx tubo digerente prime vie che può farci vedere la grandezza dell’ernia, l’EGDS o gastroscopia che ci permette di valutare se presenti ulcerazioni o sofferenze vascolari della mucosa dello stomaco ed una TAC torace-addome che permette di evidenziare se vi sia una risalita anche di altri organo come il colon, l’intestino tenue o l’omento. La manometria esofagea e la pH-impedenziometria esofagea 24h vengono eseguite solo in casi selezionati."
    subtitle3="I pazienti a- o paucisintomatici e che non presentano lesioni della mucosa dello stomaco non hanno un’indicazione assoluta al trattamento chirurgico e possono essere trattati con farmaci inibitori della pompa protonica o protettori di mucosa e seguiti nel tempo ambulatorialmente. Coloro che sono invece chiaramente sintomatici o che presentino lesioni mucose gastriche devono essere sottoposti all’intervento chirurgico.
L’intervento chirurgico viene condotto con tecnica laparoscopica mini-invasiva e prevede il riposizionamento dello stomaco e di altri visceri, eventualmente erniati, in addome, la riparazione del diaframma a livello dello jato (il punto attraverso cui è risalito lo stomaco), il confezionamento di una plastica antireflusso secondo Nissen o Toupet ed eventualmente l’ancoraggio dello stomaco in addome mediante un punto di sutura (gastropessi). In casi estremamente selezionati per la riparazione del diaframma viene utilizzata una rete protesica di rinforzo. L’intervento si è dimostrato molto efficace nella risoluzione dei suddetti sintomi in circa il 90% dei pazienti. La degenza prevista è generalmente di 3-4 giorni. Dopo l’operazione il paziente deve seguire una dieta con cibi morbidi per una decina di giorni e successivamente viene consentita una dieta libera."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Jatale",
  components: {
    PatologiesPage,
  },
};
</script>
